import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import './FeaturesPage.scss';
import seatyFeatures from './Features';
import SVGBackground from './SVGBackground';

interface IProps {}

const FeaturesPage: React.FC<IProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>Seaty - About Us</title>
        <meta name="description" content={`Information on Seaty.co.uk.`} />
      </Helmet>
      <Header />

      <div className="features-page">
        <div className="features__background">
          {/* <SVGBackground />
          <SVGBackground />
          <SVGBackground />
          <SVGBackground />
          <SVGBackground />
          <SVGBackground />
          <SVGBackground />
          <SVGBackground /> */}
        </div>

        {seatyFeatures.map((feature, index) => (
          <>
            <div className="feature__info">
              <h1 className="feature__title">{feature.title}</h1>
              <div className="feature__subtitle">{feature.subtitle}</div>
              <div className="feature__intro">{feature.intro}</div>
            </div>
            <div className="feature" key={index}>
              {index % 2 === 0 && (
                <div className="feature__left">
                  <img
                    className="feature__mascot"
                    src={`https://seatylive.blob.core.windows.net/images/Mascot/${feature.title}.png`}
                  />
                </div>
              )}

              <div className="feature__right">
                {feature.features.map((f, i) => (
                  <div className="feature__item" key={i}>
                    <div className="feature__item__image">
                      <img
                        src={`https://seatylive.blob.core.windows.net/images/FeatureIcons/${f.title}.png`}
                        alt={f.title}
                      />
                    </div>
                    <div className="feature__item_content">
                      <div className="feature__item_title">{f.title}</div>
                      <div className="feature__item_description">{f.description}</div>
                    </div>
                  </div>
                ))}
              </div>
              {index % 2 === 1 && (
                <div className="feature__left">
                  <img
                    className="feature__mascot"
                    src={`https://seatylive.blob.core.windows.net/images/Mascot/${feature.title}.png`}
                  />
                </div>
              )}
            </div>
          </>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default FeaturesPage;
